import { screens } from '../../tailwind'

export const screen = size => {
  return `@media( min-width: ${ screens[size] } )`
}

export const rem = size => {
  return `${ size / 16 }rem`
}

export const ifProp = (prop, styles) => {
  return prop ? styles : ''
}

export default {
  screen,
  rem,
  ifProp
}

export const handlePrismicLinkField = link => {
  // in order to use this make sure that the link field has the following fields
  // url
  // link_type
  // uid
  // document {
  //   __typename
  //   ... on PrismicArticle {
  //     data {
  //       article_type
  //     }
  //   }
  // }
  let format = {
    route: '',
    external: false,
    url: ''
  }
  if (link.document) {
    if (link.document[0].__typename === 'PrismicArticle') {
      let path = `/resources/${ link.document[0].data.article_type === 'blog' ? 'blog' : 'news' }/${ link.uid }`
      format.url = path
      format.route = path
    }
  }
  if (link.link_type === 'Web') {
    format.route = link.url
    format.url = link.url
    format.external = true
  }
  if (link.link_type === 'Media') {
    format.route = link.url
    format.url = link.url
    format.external = true
  }
  return format
}

export const sentenceToKebabCase = sentence => sentence && sentence.split(' ').join('-')
